export const MARKETING_API_BASE_URL = "https://api.digmstools.com" //SERVER
export const GET_ALL_USERS_ENDPOINT = "/users";
export const GET_ALL_POSITIONS_ENDPOINT = "/users/positions";
export const GET_ALL_DEPARTMENTS_ENDPOINT = "/users/departments";
export const GET_ALL_PLATFORMS_ENDPOINT = "/users/platforms";
export const GET_ALL_OFFICES_ENDPOINT = "/users/offices";
export const UPDATE_A_USER_ENDPOINT = "/users";
export const ADD_A_USER_ENDPOINT = "/users";

// export const APP_BASE_URL = "https://udp.digmstools.com" // PROD URL
export const APP_BASE_URL = "https://stage.udp.digmstools.com" // STAGE URL
export const APP_BASE_URL_DEV = "http://localhost:3000"; // DEV